:root {
    --primary: #777;
    --white: #fff;
    --gray: #D3D3D3;
    --text: #262626;
    --like: #111;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.Cardbody {
    display: flex;
    background-color: var(--background);
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    height: 100vh;
    padding: 1rem;
}
  
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
  
.card {
    background-color: rgb(255, 255, 255);
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
    display: flex;
    width: 22rem;
    height: 29rem;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative; /* Add this line */
    padding-bottom: 30px;
}
  
.card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 16px;
}
  
.card__preview {
    height: 12rem;
    width: 100%;
    position: relative;
    overflow: hidden;
}
  
.card__preview img {
    width: 20%;
    object-fit: cover;
    transition: all 0.4s ease-out;
}
  
.card:hover .card__preview img {
    transform: scale(1.35);
}
  
.card__address {
    margin-top: 0.5rem;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    margin-top: 1rem;
}
  
.card__price {
    background-color: var(--white);
    color: var(--text);
    z-index: 1;
    position: absolute;
    bottom: 1.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    left: 1.25rem;
    font-weight: bold;
}

.score-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 20px auto; 
    font-weight: bold;
}

.card_content {
    text-align: center; 
}

.card_score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 2em; 
    font-weight: bold; 
    margin: 0 auto; 
    background-color: var(--scoreColor, #D3D3D3); 
}
  
.card_last_updated {
    color: var(--gray);
    font-size: 0.9rem;
    font-style: italic;
    position: absolute; 
    bottom: 10px; 
    width: 100%; 
    text-align: center; 
    margin: 0; 
}

.analysis_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
  


.card.analysis_card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.arrow {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s ease;
    font-weight: bold; /* Makes the arrow bolder */
    font-size: 1.2em; 
    color: #333;
    margin-left: 5px; 
    transition: transform 0.3s ease; 
    
}

.card_btn:hover .arrow {
    animation: moveArrow 0.5s ease-in-out forwards;
}

@keyframes moveArrow {
    0% { transform: translateX(0); }
    100% { transform: translateX(5px); }
}

.arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Adjust size as needed */
    height: 40px;
    border-radius: 50%;
    background-color: #333; /* Choose a suitable color */
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease;
}

.arrow-container::before {
    content: '→';
    color: #fff; /* Adjust color as needed */
    font-size: 1.2em; /* Adjust size as needed */
    transition: transform 0.2s ease-out;
}

.card_btn:hover .arrow-container {
    background-color: #777; /* Color on hover */
}

.card_btn:hover .arrow-container::before {
    transform: translateX(5px); /* Arrow movement on hover */
}


.card_title {
    font-family:'Open Sans'; /* A clean, modern font */
    font-size: 1.8rem; /* Adjust size as needed */
    font-weight: 600; /* Medium weight for presence */
    color: var(--primary); /* A color variable defined in :root */
    text-transform: uppercase; /* Uppercase for a uniform look */
    letter-spacing: 1px; /* Spacing for readability */
    margin-bottom: 1rem; /* Space below the title */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.card:hover .card_title {
    color: var(--like); /* A different color on hover */
}