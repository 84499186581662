footer {
    background-color: #f5f5f5; /* Light gray background */
    color: #333; /* Dark gray text color */
    padding: 20px 0; /* Add padding for breathing room */
    border-top: 1px solid #e0e0e0; /* Subtle top border */
    font-family: sans-serif; /* Legible font family */
}
  
footer a {
    color: #007bff; /* Blue link color */
    text-decoration: none; /* Remove underlines */
}
  
footer a:hover {
    color: #0056b3; /* Darker blue on hover */
}
  
footer .grid-container {
    display: flex; /* Use flexbox for flexible layout */
    justify-content: space-between; /* Evenly distribute elements */
    align-items: center; /* Vertically center content */
}
  
footer .grid-item {
    padding: 0 15px; /* Add spacing between grid items */
}
  
footer .copyright {
    font-size: 14px; /* Slightly smaller font size */
    color: #666; /* Lighter gray color */
}
  
 
footer .social-icons {
    display: flex; /* Display icons in a row */
    gap: 15px; /* Add spacing between icons */
}
  
footer .social-icon {
    width: 30px;
    height: 30px;
    fill: #333; /* Icon color */
}