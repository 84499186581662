.holder-list {
    padding: 20px;
    font-family: Arial, sans-serif;
    justify-content: center;
}

.holders-container {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: flex-start; /* Ensure all columns start from the top */
}

.fund-holders, .inst-holders {
    flex: 1; /* This will ensure both take equal width */
    margin-right: 20px; /* Optional: Spacing between the two lists */
}

.holder-card {
    background-color: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.major-target-container {
    display: flex;
    flex-direction: column;
}

.header {
    font-family: 'Open Sans';
    color: #777; /* Choose your color */
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px; /* Choose your size */
    font-weight: 600; /* Choose your weight */
}

.sub-header {
    font-family: 'Open Sans';
    color: #777; 
    text-align: center;
    font-size: 18px; 
    font-weight: 600;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
}

.separator {
    margin: 40px;
    text-align: center;
    font-weight: bold;
}


.chatResponseContainer {
    background-color: rgba(255, 255, 255, 0.9); /* Adjust opacity as needed */
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0; /* Adjusted to only apply to top and bottom */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 100%; /* Ensure the box doesn't exceed the width of its container */
    box-sizing: border-box; /* Include padding and border in the box's width */
}

.chatResponseText {
    white-space: pre-wrap; /* Allows text to wrap and preserves whitespace */
    word-wrap: break-word; /* Ensures long words do not overflow */
    overflow-wrap: break-word; /* Standard property for word-wrap */
    text-align: left; /* Align text to the left */
    font-size: calc(14px + 0.5vw); /* Responsive font size */
    color: #333; /* Dark color for text */
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); /* Text shadow for legibility */
}