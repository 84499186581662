.contact-form {
    background-color: #999;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Courier New', monospace;
    width: 60%;
    margin: auto;
    margin-top: 5%;
}
  
.contact-form-title {
    font-size: 24px;
    border-bottom: 2px solid #fff;
    margin-bottom: 20px;
    text-align: center;
}
  
.input-field, textarea {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    color: #ffffff;
}
  
.input-field::placeholder, textarea::placeholder {
    color: #ffffff;
}
  
.submit-button {
    background-color: #1976d2;
    color: #1a1a1a;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.submit-button:hover {
    background-color: #e6b800;
}

.email-success-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.email-success-content {
    background-color: #333;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    text-align: center;
}