.App {
  text-align: center;
  flex-wrap: wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ticker {
  0% { transform: translateX(0); }
  100% { transform: translateX(var(--ticker-end)); }
}

.stock-ticker {
  --ticker-start: 100%;
  --ticker-end: -100%;
  height: 50px;
  overflow: hidden;
  position: relative;
  white-space:nowrap;
  background-color: #333;
  z-index: 5000000;
  min-width: 100vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

#ticker div {
  position: absolute;
  width:100%;
  height: 100%;
  line-height:50px;
}

.green {
  color: limegreen;
}

.red {
  color: red;
}

.ticker-content {
  height: 100%;
  line-height: 50px;
  display: inline-block;
  animation: ticker var(--ticker-duration) linear infinite;
  animation-delay: 1s;
  white-space: nowrap;
}





body {
  overflow-x: hidden;
}

.drag-handle {
  position: absolute;
  right: 0; 
  top: 50%;
  transform: translateY(-50%); 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; 
  height: 40px; 
  background-color: #575757;
  color: white;
  border-radius: 50%; 
}


.App {
  display: flex;
}

.navbar {
  background-color: #444;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 100%;  
  justify-content: center;
  z-index: 50000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.navbar a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-family: 'Open Sans';
}

.navbar a:hover {
  background-color: #333;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;   /* Takes full viewport height */
}

#model-select.MuiOutlinedInput-root {
  background-color: #444 !important;
}
